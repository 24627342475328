'use strict'

import $ from 'jquery'
import Parsley from 'parsleyjs'


$(document).ready(function($) {

	setupForm();

	function setupForm() {
		Parsley.addMessages('pl', {
				defaultMessage: "Wartość wygląda na nieprawidłową",
				type: {
					email:        "Wpisz poprawny adres e-mail",
					url:          "Wpisz poprawny adres URL",
					number:       "Wpisz poprawną liczbę",
					integer:      "Dozwolone są jedynie liczby całkowite",
					digits:       "Dozwolone są jedynie cyfry",
					alphanum:     "Dozwolone są jedynie znaki alfanumeryczne"
				},
				notblank:       "Pole nie może być puste",
				required:       "Pole jest wymagane",
				pattern:        "Pole zawiera nieprawidłową wartość. Wpisz numer telefonu w formacie 9-cyfrowym, np. 123123123",
				min:            "Wartość nie może być mniejsza od %s",
				max:            "Wartość nie może być większa od %s",
				range:          "Wartość powinna zaweriać się pomiędzy %s a %s",
			// minlength:      "Minimalna ilość znaków wynosi %s",
			minlength:      "Wpisz telefon w formacie 123-456-789",
			maxlength:      "Maksymalna ilość znaków wynosi %s",
			length:         "Ilość znaków wynosi od %s do %s",
			mincheck:       "Wybierz minimalnie %s opcji",
			maxcheck:       "Wybierz maksymalnie %s opcji",
			check:          "Wybierz od %s do %s opcji",
			equalto:        "Wartości nie są identyczne"
		})

		Parsley.setLocale('pl')
	}

	function submitForm() {
		let data = $('#form').serialize();

		$.ajax({
			url : '/request.php',
			type : 'POST',
			cache : false,
			data : data,
			success : function(res) {
				res = JSON.parse(res);
			}
		})
	}

	$('#form').parsley()

	$('#form').on('submit', function(event) {
		event.preventDefault()

		var formParsley = $('#form').parsley();

		formParsley.destroy()

		if (formParsley.validate()) {
			submitForm()
		}
	});

	
	var $btnUpload = $('.jblLV-file-data');

	$('.jblLV-disclaimer-toggle').on('click', function(event) {
		event.preventDefault();
		
		if ($('.jblLV-disclaimer-more').hasClass('hidden')) {
			$(this).text('mniej');
			$('.jblLV-disclaimer-more').removeClass('hidden')
		} else {
			$(this).text('więcej')
			$('.jblLV-disclaimer-more').addClass('hidden')
		}
	});

	$btnUpload.on('change', function(event) {
		event.preventDefault();
		uploadFile( $(this).get(0).files[0] );
	});

	function makeid() {
		var text = "";
		var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

		for (var i = 0; i < 5; i++)
			text += possible.charAt(Math.floor(Math.random() * possible.length));

		return text;
	}

	function setPathToUploadedFile(url) {
		var imagePath = location.origin+'/'+url;

		// $('.jblLV-file-url').attr('value', imagePath);
		var item = document.querySelector('.jblLV-file-url');
		item.value = imagePath;
		$('.jblLV-file-btn span').text('ZMIEŃ PARAGON');
		$('.jblLV-file-imageout').html('<p class="jblLV-file-preview"><img src="'+imagePath+'"></p>');
	}

	function viewMessage(data) {
		var status = data.status;
		var message = data.message;

		var text = '';

		var $output = $('.jblLV-file-imageout');

		switch (message) {
			case 'uszkodzony':
				text = 'Plik uszkodzony. Prosimy wybrać inny.'
				break;
			case 'duplikat':
				text = 'Wystąpił błąd.'
				break;

			case 'large_file':
				text = 'Zbyt duży rozmiar pliku. Prosimy wybrać inny.'
				break;

			default:
				text = 'Wystąpił nieoczekiwany błąd. Prosimy wybrać inny plik.'
				break;
		}


		$output.html('<p class="error">'+text+'</p>')
	}

	function uploadFile(file) {
        var fd = new FormData(document.getElementById("form"));
        
        fd.append("label", makeid()+'-');

        $.ajax({
          url: "upload.php",
          type: "POST",
          data: fd,
          processData: false,
          contentType: false,
          beforeSend: function() {
          	$('.jblLV-file-imageout').html('<p><img class="preloader" src="/assets/img/preloader.gif"></p>')
          	$('.jblLV-file-url-outer .parsley-errors-list').remove()
          }
        }).done(function( data ) {
        	var data = JSON.parse(data);

        	if (data.status == 'success') {
        		setPathToUploadedFile(data.path)
        	} else {
        		viewMessage(data);
        	}

        });
	}

});